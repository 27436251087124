import React from "react";
import { Avatar, Button, List, Modal, Space, Typography } from "antd";
import { CHAINS } from "../../../../../consts/chains";
import { MAINNET } from "../../../../../consts/global";
import PairItem from "./PairItem";
import { getTokenByHash } from "../../../../../common/helpers";
import { LineChartOutlined } from "@ant-design/icons";
import shortNumber from "@pogix3m/short-number";
import { withDecimal } from "../../../../../packages/neo/utils";
interface ITokenItem {
  chain: CHAINS;
  data: {
    token: string;
    price?: string;
    liquidityUSD?: string;
    liquidity: string;
    mc?: string;
    pairs: {
      tokenA: string;
      tokenB: string;
      reserves: {
        amountA: string;
        amountB: string;
      };
    }[];
  };
  onClick: (val: string[]) => void;
}
const TokenItem = ({ chain, data, onClick }: ITokenItem) => {
  let token = getTokenByHash(chain, MAINNET, data.token);
  const [pairs, setPairs] = React.useState<any[] | undefined>(undefined);
  if (token === undefined) return null;
  return (
    <List.Item>
      <List.Item.Meta
        avatar={<Avatar size={"small"} src={token.icon} />}
        title={
          <Typography.Text className="is-accent" strong>
            {token.symbol}
          </Typography.Text>
        }
        description={
          <>
            <Space direction="vertical">
              <Space size="small">
                {data.price && (
                  <Typography.Text className="is-accent">
                    <small>${data.price}</small>
                  </Typography.Text>
                )}
                {data.liquidityUSD ? (
                  <Typography.Text className="is-accent">
                    <small>LIQ:${shortNumber(data.liquidityUSD)}</small>
                  </Typography.Text>
                ) : (
                  <Typography.Text className="is-accent">
                    <small>
                      LIQ:{withDecimal(data.liquidity, token.decimals)}
                    </small>
                  </Typography.Text>
                )}

                {data.mc && (
                  <Typography.Text className="is-accent">
                    <small>MC:${shortNumber(data.mc)}</small>
                  </Typography.Text>
                )}
              </Space>

              <Space>
                <Button
                  onClick={() => onClick([data.token])}
                  size="small"
                  icon={<LineChartOutlined />}
                >
                  View
                </Button>
                <Button onClick={() => setPairs(data.pairs)} size="small">
                  Pairs
                </Button>
              </Space>
            </Space>
          </>
        }
      />

      <Modal
        open={pairs ? true : false}
        onCancel={() => setPairs(undefined)}
        footer={[
          <Button key="back" onClick={() => setPairs(undefined)}>
            Close
          </Button>,
        ]}
      >
        <List
          bordered={false}
          pagination={false}
          dataSource={pairs}
          renderItem={(item: any, i) => (
            <PairItem
              chain={chain}
              key={"token" + i}
              defaultToken={data.token}
              data={item}
              onClick={onClick}
            />
          )}
        />
      </Modal>
    </List.Item>
  );
};

export default TokenItem;
