import React from "react";
import { Avatar, List, Space, Typography } from "antd";
import { CHAINS } from "../../../../../consts/chains";
import { MAINNET } from "../../../../../consts/global";
import { toDecimal, withDecimal } from "../../../../../packages/neo/utils";
import { getTokenByHash } from "../../../../../common/helpers";
interface IPairItem {
  chain: CHAINS;
  defaultToken: string;
  data: {
    tokenA: string;
    tokenB: string;
    reserves: {
      amountA: string;
      amountB: string;
    };
  };
  onClick: (val: string[]) => void;
}
const PairItem = ({ defaultToken, chain, data, onClick }: IPairItem) => {
  let tokenA = getTokenByHash(chain, MAINNET, data.tokenA);
  let tokenB = getTokenByHash(chain, MAINNET, data.tokenB);

  if (tokenA === undefined || tokenB === undefined) return null;
  return (
    <List.Item>
      <Space
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Space size="large">
          <Space size="small">
            <Avatar
              size={"small"}
              src={defaultToken === tokenA.hash ? tokenA.icon : tokenB.icon}
            />
            <Avatar
              size={"small"}
              src={defaultToken === tokenA.hash ? tokenB.icon : tokenA.icon}
            />
          </Space>

          <div>
            <div>
              <Typography.Text>
                {withDecimal(data.reserves.amountA, tokenA.decimals, true)}
              </Typography.Text>
              <Typography.Text strong>{tokenA.symbol}</Typography.Text>
            </div>
            <div>
              <Typography.Text>
                {withDecimal(data.reserves.amountB, tokenB.decimals, true)}
              </Typography.Text>
              <Typography.Text strong> {tokenB.symbol}</Typography.Text>
            </div>
          </div>
        </Space>
        {/* <Button onClick={() => onClick([data.tokenA, data.tokenB])} size="small">
        View
      </Button> */}
      </Space>
    </List.Item>
  );
};

export default PairItem;
