import {
  MAINNET,
  TESTNET,
  SMITH,
  SWAP,
  FARM,
  HELPERS,
} from "../../../consts/global";

export const NEOX_MAINNET_CONTRACTS = {
  SMITH: "0x67DEE0d471D516221EF107c0d7aB6Ce538CbC4Ec",
  NEP: "0xaA80811B446b623AD7df6b11CE16e64DB72f6acE",
  SWAP: "0xE1e1cC68841D1fF13bdd8C8fB36E6f91995788f4",
  FARM: "0x65DddC5A6DB5dd43f02894fc5e31D17b4E65449f",
  FNEO: "",
  GAS: "",
  WGAS: "0xdE41591ED1f8ED1484aC2CD8ca0876428de60EfF",
  HELPERS: "0xDfa53F8deA15CE39A23D57874e03eE0E76425e01",
};

export const NEOX_TESTNET_CONTRACTS = {
  SMITH: "0x36f3b75D455F293eA9dCE48cEcb6790E311FcfDD",
  NEP: "",
  SWAP: "0x1dD0050e857b28855dc1d51Bb6594aed70f7E26d",
  FARM: "",
  FNEO: "",
  GAS: "",
  WGAS: "0x1CE16390FD09040486221e912B87551E4e44Ab17",
  HELPERS: "0x4E428694Be3A7b804cF36a1776Ed82185853764F",
};

export const NEOX_CONTRACTS = {
  [MAINNET]: {
    [SMITH]: NEOX_MAINNET_CONTRACTS.SMITH,
    [SWAP]: NEOX_MAINNET_CONTRACTS.SWAP,
    [FARM]: NEOX_MAINNET_CONTRACTS.FARM,
    [HELPERS]: NEOX_MAINNET_CONTRACTS.HELPERS,
  },
  [TESTNET]: {
    [SMITH]: NEOX_TESTNET_CONTRACTS.SMITH,
    [SWAP]: NEOX_TESTNET_CONTRACTS.SWAP,
    [FARM]: NEOX_TESTNET_CONTRACTS.FARM,
    [HELPERS]: NEOX_MAINNET_CONTRACTS.HELPERS,
  },
};

export const NEOX_NEP_ADDRESSES = {
  [MAINNET]: NEOX_MAINNET_CONTRACTS.NEP,
  [TESTNET]: NEOX_TESTNET_CONTRACTS.NEP,
};
