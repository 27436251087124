import React, { useEffect, useState } from "react";
import { Modal, Space, Spin, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { CHAINS } from "../../../../../consts/chains";
import { INetworkType } from "../../../../../packages/neo/network";
import {
  approve,
  getAllowances,
} from "../../../../../packages/evm/contracts/swap";
import { waitTransactionUntilSubmmited } from "../../../../../common/routers/global";
import { TxResult } from "../../../../components/TxResult";
import { HELPERS, NEO_CHAIN, SWAP } from "../../../../../consts/global";
import { ethers } from "ethers";
import { DisplayAd } from "../../../Swap/components/Actions/components/DisplayAd";
import { toolsRouter } from "../../../../../common/routers";
import { IConnectedWallet } from "../../../../../packages/neo/wallets/interfaces";
import {
  IBatchTransaferAsset,
  IExcelData,
} from "../../../../../common/routers/tools";
import { useAccount } from "wagmi";
import { EVM_CONTRACTS } from "../../../../../packages/evm";

interface IActionModalProps {
  chain: CHAINS;
  network: INetworkType;
  list: IExcelData[][];
  connectedWallet?: IConnectedWallet;
  onSuccess: () => void;
  onCancel: () => void;
}

const ActionModal = (props: IActionModalProps) => {
  const { chain, network, list, connectedWallet, onSuccess, onCancel } = props;
  const { address } = useAccount();
  const [status, setStatus] = useState({
    isProcessing: false,
    message: "",
    error: "",
  });
  const [txid, setTxid] = useState<string | undefined>();
  const [isSubmitting, setSubmitting] = useState(false);

  const doInvoke = async () => {
    const transferList: IBatchTransaferAsset[] = [];
    const approvalList = {};

    try {
      list.map((item: any) => {
        const amount: bigint = ethers.parseUnits(
          item[1].value.toString(),
          item[4].value
        );
        const receiver = item[0].value;
        const tokenHash = item[3].value;
        transferList.push({
          receiver,
          amount: amount.toString(),
          tokenHash,
        });

        if (!approvalList[tokenHash]) {
          approvalList[tokenHash] = BigInt(0); // Initialize with BigInt
        }
        approvalList[tokenHash] = approvalList[tokenHash] + amount; // Add BigInt amounts
      });
    } catch (e) {
      console.error(e);
      setStatus({
        isProcessing: false,
        message: "",
        error: "Invalid input. Please check the input values.",
      });
      return;
    }

    if (chain !== NEO_CHAIN) {
      const HELPER_CONTRACT_ADDRESS = EVM_CONTRACTS[chain][network][HELPERS];
      setStatus({
        isProcessing: true,
        message: `Checking allowlance`,
        error: "",
      });

      const allowances = await getAllowances(
        chain,
        network,
        address as any,
        Object.keys(approvalList),
        HELPER_CONTRACT_ADDRESS
      );

      let i = 0;
      for (const [tokenHash, totalAmount] of Object.entries(approvalList) as [
        string,
        bigint
      ][]) {
        if (allowances[i] < totalAmount) {
          setStatus({
            isProcessing: true,
            message: `Check your wallet to approve.`,
            error: "",
          });
          let approveTx;
          try {
            approveTx = await approve(
              chain,
              network,
              tokenHash,
              HELPER_CONTRACT_ADDRESS
            );
          } catch (e: any) {
            console.error(e);
            setStatus({
              isProcessing: false,
              message: "",
              error: `Failed to approve. Try again`,
            });
            return;
          }
          setStatus({
            isProcessing: true,
            message: `Submitting`,
            error: "",
          });
          await waitTransactionUntilSubmmited(chain, network, approveTx);
          // Your logic here
        }
      }
    }

    let tx;

    try {
      setStatus({
        isProcessing: false,
        message: "Check your wallet to confirm the transaction.",
        error: "",
      });
      tx = await toolsRouter.massTransfers(
        chain,
        network,
        transferList,
        connectedWallet
      );
    } catch (e: any) {
      console.error(e);
      setStatus({
        isProcessing: false,
        message: "",
        error: "Failed to submit the transaction.",
      });
      return;
    }

    setSubmitting(true);
    await waitTransactionUntilSubmmited(chain, network, tx);

    setTxid(tx);
  };

  useEffect(() => {
    doInvoke();
  }, [chain, network, list]);

  return (
    <Modal open={true} onCancel={onCancel} footer={[]} closeIcon={false}>
      <div className="has-text-centered">
        <h3 className="title is-5">Batch Transfers</h3>
        <div className="block">
          {txid ? (
            <TxResult
              txid={txid}
              chain={chain}
              network={network}
              onClose={onCancel}
            />
          ) : isSubmitting ? (
            <DisplayAd />
          ) : (
            <>
              <Space>
                {status.message && (
                  <Typography.Text>{status.message}</Typography.Text>
                )}
                {status.isProcessing && (
                  <Spin indicator={<LoadingOutlined spin />} />
                )}
                {status.error && (
                  <Typography.Text type="danger">
                    {status.error}
                  </Typography.Text>
                )}
              </Space>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ActionModal;
