import { wallet as neonWallet } from "@cityofzion/neon-core";
import { CHAINS } from "../../../consts/chains";
import { getNEP17TransferScript } from "../../../packages/neo/utils";
import { IConnectedWallet } from "../../../packages/neo/wallets/interfaces";
import { INetworkType } from "../../../packages/neo/network";
import { getDefaultWitnessScope } from "../../../packages/neo/utils";
import { NEO_CHAIN } from "../../../consts/global";
import { NeoWallets } from "../../../packages/neo/wallets";
import { ethers } from "ethers";
import { batchTransfers } from "../../../packages/evm/contracts/helpers";

export interface IExcelData {
  type: any;
  value: any;
}

export interface IBatchTransaferAsset {
  tokenHash: string;
  receiver: string;
  amount: string;
}

export const generatePrivatekeys = (
  chain: CHAINS,
  amount: number
): IExcelData[][] => {
  const list: IExcelData[][] = []; // Declare list once

  switch (chain) {
    case NEO_CHAIN: {
      for (let i = 0; i < amount; i++) {
        const row: IExcelData[] = [];
        const p = neonWallet.generatePrivateKey();
        const account = new neonWallet.Account(p);
        row.push({
          type: String,
          value: account.address,
        });
        row.push({
          type: String,
          value: p,
        });
        list.push(row);
      }
      break;
    }
    default: {
      for (let i = 0; i < amount; i++) {
        const row: IExcelData[] = [];
        const wallet = ethers.Wallet.createRandom();
        row.push({
          type: String,
          value: wallet.address,
        });
        row.push({
          type: String,
          value: wallet.privateKey,
        });
        list.push(row);
      }
      break;
    }
  }

  return list; // Return the list regardless of the chain
};
export const massTransfers = async (
  chain: CHAINS,
  network: INetworkType,
  list: IBatchTransaferAsset[],
  connectedWallet?: IConnectedWallet
): Promise<string> => {
  switch (chain) {
    case NEO_CHAIN:
      if (connectedWallet) {
        const senderHash = neonWallet.getScriptHashFromAddress(
          connectedWallet.account.address
        );
        const batch: any[] = [];

        list.forEach((item) => {
          const script = getNEP17TransferScript(
            item.tokenHash,
            senderHash,
            neonWallet.isAddress(item.receiver)
              ? neonWallet.getScriptHashFromAddress(item.receiver)
              : item.receiver,
            item.amount
          );
          batch.push(script);
        });

        const signers = [getDefaultWitnessScope(senderHash)];

        return NeoWallets.invokeMulti(connectedWallet, network, batch, signers);
      } else {
        throw new Error("Connect wallet.");
      }
    default:
      return await batchTransfers(chain, network, list);
  }
};
