// export const BOYZ_RARITY = {
//   background: [
//     "Sky",
//     "Underworld",
//     "Neo",
//     "White",
//     "Purple",
//     "Pink",
//     "Red",
//     "Orange",
//     "Yellow",
//     "Green",
//     "Blue",
//     "Grey",
//     "Mint",
//     "Black",
//   ],
//   body: ["Dark Skin", "Light Skin", "Tan Skin"],
//   clothing: [
//     "Hacker Hoodie",
//     "Bear Hoodie",
//     "Spacesuit",
//     "Outlaw Shirt",
//     "Scarf",
//     "White FTW Sweater",
//     "Sailor",
//     "White Rune Sweater",
//     "Black Rune Sweater",
//     "White Rune Hoodie",
//     "Black Rune Hoodie",
//     "Black Rune Sweater",
//     "Colorful Sweater",
//     "None",
//     "White FTW Hoodie",
//     "Pink Shirt",
//     "Suit",
//     "Gold Chain",
//     "Rocket Sweater",
//     "Black FTW Hoodie",
//     "Striped Shirt",
//     "Silver Chain",
//     "Orange Shirt",
//     "Retro Sweater",
//     "Party Shirt",
//     "Heart Shirt",
//     "Striped Sweater",
//     "Candy Shirt",
//     "Black Turtleneck",
//     "Green Collar",
//     "Neo Shirt",
//     "White Turtleneck",
//     "Pink Hoodie",
//     "Tank Top",
//     "Grey Shirt",
//     "Jersey",
//     "BW Hoodie",
//     "Tracksuit",
//     "Green Hoodie",
//   ],
//   head: [
//     "Black Headphones",
//     "Blonde Hair",
//     "Pink Headphones",
//     "Red Headphones",
//     "Green Headphones",
//     "Space Helmet",
//     "Santa Hat",
//     "Space Warrior Hair",
//     "Wreathe",
//     "Viking Helmet",
//     "Little Horns",
//     "Halo",
//     "Wizard Hat",
//     "Crown",
//     "Party Hat",
//     "Pilot Goggles",
//     "Horns",
//     "Police Hat",
//     "Outlaw Hat",
//     "M Hat",
//     "BW Afro",
//     "Helicopter Hat",
//     "Army Helmet",
//     "Black Hair",
//     "Clown Hair",
//     "Afro",
//     "Neo Hat",
//     "Top Hat",
//     "Orange Hat",
//     "Red Hat",
//     "Beret",
//     "Grey Hat",
//     "Explorer Hat",
//     "Purple Messy Hair",
//     "Sunset Hat",
//     "Bandana",
//     "Durag",
//     "Black Mohawk",
//     "Headband",
//     "Blue Messy Hair",
//     "Brown Mohawk",
//     "White Hat",
//     "Bald",
//     "Black Messy Hair",
//     "Beanie",
//     "Dreads",
//     "Red Messy Hair",
//     "String Hair",
//   ],
//   eyes: [
//     "Scuba Gear",
//     "Scouter",
//     "Tears",
//     "Ninja",
//     "Gold Glasses",
//     "VR",
//     "Hearts",
//     "3D Glasses",
//     "Neo Glasses",
//     "Laser",
//     "Neo's Glasses",
//     "White Sunset Glasses",
//     "Pixel Glasses",
//     "Green",
//     "Red",
//     "Blue Glasses",
//     "Sunset Glasses",
//     "Rainbow Glasses",
//     "Green Glasses",
//     "White Glasses",
//     "Normal",
//     "Cool",
//   ],
//   mouth: [
//     "Green Smoke",
//     "Pipe",
//     "Goatee",
//     "White Beard",
//     "Black Beard",
//     "Brown Beard",
//     "5 O'clock Shadow",
//     "Smoke",
//     "Unimpressed",
//     "Smirk",
//   ],
//   accessory: ["Clown Nose", "Gold Earing", "Silver Earing", "None"],
// };

export const BOYZ_RARITY_WITH_PERCENT = {
  background: [
    { value: "Black", rarity: 0.18 },
    { value: "Sky", rarity: 1.08 },
    { value: "Underworld", rarity: 1.26 },
    { value: "Neo", rarity: 3.42 },
    { value: "White", rarity: 4.68 },
    { value: "Purple", rarity: 6.03 },
    { value: "Yellow", rarity: 6.75 },
    { value: "Pink", rarity: 7.11 },
    { value: "Orange", rarity: 8.01 },
    { value: "Red", rarity: 8.37 },
    { value: "Green", rarity: 9.9 },
    { value: "Grey", rarity: 11.43 },
    { value: "Blue", rarity: 11.7 },
    { value: "Mint", rarity: 20.07 },
  ],
  body: [
    { value: "Dark Skin", rarity: 31.68 },
    { value: "Tan Skin", rarity: 33.93 },
    { value: "Light Skin", rarity: 34.38 },
  ],
  clothing: [
    { value: "Hacker Hoodie", rarity: 0.18 },
    { value: "Bear Hoodie", rarity: 0.54 },
    { value: "Spacesuit", rarity: 0.72 },
    { value: "Scarf", rarity: 1.26 },
    { value: "Black Rune Hoodie", rarity: 1.26 },
    { value: "White Rune Sweater", rarity: 1.44 },
    { value: "Outlaw Shirt", rarity: 1.44 },
    { value: "Black Rune Sweater", rarity: 1.53 },
    { value: "White FTW Sweater", rarity: 1.53 },
    { value: "White Rune Hoodie", rarity: 1.8 },
    { value: "None", rarity: 1.8 },
    { value: "Black FTW Sweater", rarity: 1.98 },
    { value: "Colorful Sweater", rarity: 2.07 },
    { value: "Gold Chain", rarity: 2.07 },
    { value: "Suit", rarity: 2.16 },
    { value: "Party Shirt", rarity: 2.34 },
    { value: "Black FTW Hoodie", rarity: 2.34 },
    { value: "Orange Shirt", rarity: 2.43 },
    { value: "Pink Shirt", rarity: 2.43 },
    { value: "Silver Chain", rarity: 2.52 },
    { value: "Sailor", rarity: 2.52 },
    { value: "Candy Shirt", rarity: 2.61 },
    { value: "White FTW Hoodie", rarity: 2.7 },
    { value: "Rocket Sweater", rarity: 2.7 },
    { value: "Striped Sweater", rarity: 2.88 },
    { value: "Heart Shirt", rarity: 2.97 },
    { value: "Black Turtleneck", rarity: 2.97 },
    { value: "Striped Shirt", rarity: 3.15 },
    { value: "Retro Sweater", rarity: 3.24 },
    { value: "Tank Top", rarity: 3.33 },
    { value: "Green Collar", rarity: 3.33 },
    { value: "Grey Shirt", rarity: 3.69 },
    { value: "BW Hoodie", rarity: 3.87 },
    { value: "White Turtleneck", rarity: 3.96 },
    { value: "Pink Hoodie", rarity: 4.14 },
    { value: "Neo Shirt", rarity: 4.32 },
    { value: "Jersey", rarity: 4.32 },
    { value: "Tracksuit", rarity: 4.32 },
    { value: "Green Hoodie", rarity: 5.13 },
  ],
  head: [
    { value: "Pink Headphones", rarity: 0.45 },
    { value: "Red Headphones", rarity: 0.45 },
    { value: "Black Headphones", rarity: 0.45 },
    { value: "Blonde Hair", rarity: 0.54 },
    { value: "Green Headphones", rarity: 0.81 },
    { value: "Wreathe", rarity: 0.81 },
    { value: "Space Helmet", rarity: 0.9 },
    { value: "Viking Helmet", rarity: 0.9 },
    { value: "Pilot Goggles", rarity: 0.99 },
    { value: "Santa Hat", rarity: 0.99 },
    { value: "Wizard Hat", rarity: 0.99 },
    { value: "Horns", rarity: 1.08 },
    { value: "Little Horns", rarity: 1.08 },
    { value: "Space Warrior Hair", rarity: 1.17 },
    { value: "Army Helmet", rarity: 1.26 },
    { value: "Crown", rarity: 1.35 },
    { value: "Halo", rarity: 1.44 },
    { value: "Party Hat", rarity: 1.53 },
    { value: "Police Hat", rarity: 1.53 },
    { value: "Black Hair", rarity: 1.53 },
    { value: "Outlaw Hat", rarity: 1.53 },
    { value: "BW Afro", rarity: 1.98 },
    { value: "Clown Hair", rarity: 1.98 },
    { value: "Explorer Hat", rarity: 2.07 },
    { value: "Helicopter Hat", rarity: 2.16 },
    { value: "Afro", rarity: 2.16 },
    { value: "M Hat", rarity: 2.25 },
    { value: "Orange Hat", rarity: 2.25 },
    { value: "Top Hat", rarity: 2.43 },
    { value: "Purple Messy Hair", rarity: 2.61 },
    { value: "Beret", rarity: 2.61 },
    { value: "Neo Hat", rarity: 2.7 },
    { value: "Red Hat", rarity: 2.7 },
    { value: "Bald", rarity: 2.97 },
    { value: "String Hair", rarity: 3.06 },
    { value: "Bandana", rarity: 3.06 },
    { value: "Dreads", rarity: 3.06 },
    { value: "Black Messy Hair", rarity: 3.15 },
    { value: "Headband", rarity: 3.15 },
    { value: "Sunset Hat", rarity: 3.15 },
    { value: "Grey Hat", rarity: 3.15 },
    { value: "Brown Mohawk", rarity: 3.33 },
    { value: "Durag", rarity: 3.42 },
    { value: "Beanie", rarity: 3.51 },
    { value: "Blue Messy Hair", rarity: 3.69 },
    { value: "White Hat", rarity: 3.78 },
    { value: "Black Mohawk", rarity: 3.78 },
    { value: "Red Messy Hair", rarity: 4.05 },
  ],
  eyes: [
    { value: "Scuba Gear", rarity: 0.54 },
    { value: "Scouter", rarity: 1.08 },
    { value: "3D Glasses", rarity: 1.8 },
    { value: "Gold Glasses", rarity: 1.89 },
    { value: "Ninja", rarity: 2.07 },
    { value: "Tears", rarity: 2.16 },
    { value: "Hearts", rarity: 2.25 },
    { value: "VR", rarity: 2.34 },
    { value: "Neo Glasses", rarity: 2.43 },
    { value: "Laser", rarity: 2.52 },
    { value: "Neo's Glasses", rarity: 3.6 },
    { value: "White Sunset Glasses", rarity: 3.69 },
    { value: "Sunset Glasses", rarity: 4.32 },
    { value: "Blue Glasses", rarity: 4.5 },
    { value: "Red", rarity: 4.77 },
    { value: "Green", rarity: 4.86 },
    { value: "Rainbow Glasses", rarity: 5.31 },
    { value: "Pixel Glasses", rarity: 5.67 },
    { value: "White Glasses", rarity: 5.67 },
    { value: "Green Glasses", rarity: 5.67 },
    { value: "Normal", rarity: 14.67 },
    { value: "Cool", rarity: 18.18 },
  ],
  mouth: [
    { value: "Green Smoke", rarity: 2.79 },
    { value: "Goatee", rarity: 4.23 },
    { value: "Pipe", rarity: 4.68 },
    { value: "White Beard", rarity: 5.31 },
    { value: "Black Beard", rarity: 7.02 },
    { value: "Brown Beard", rarity: 7.92 },
    { value: "5 O'clock Shadow", rarity: 9 },
    { value: "Smoke", rarity: 10.17 },
    { value: "Unimpressed", rarity: 15.48 },
    { value: "Smirk", rarity: 33.39 },
  ],
  accessory: [
    { value: "Clown Nose", rarity: 0.45 },
    { value: "Gold Earing", rarity: 18.27 },
    { value: "Silver Earing", rarity: 24.84 },
    { value: "None", rarity: 56.44 },
  ],
};
