import React, { useState } from "react";
import { IExcelData } from ".";
import { useApp } from "../../../../../common/hooks/use-app";
import { toolsRouter } from "../../../../../common/routers";
import { Form, InputNumber, Button, Space, Divider, Alert } from "antd";

interface INewWalletGeneratorProps {
  onSubmit: (list: IExcelData[][]) => void;
}

const NewWalletGenerator = ({ onSubmit }: INewWalletGeneratorProps) => {
  const { chain } = useApp();
  const [amount, setAmount] = useState<number | undefined>();

  const handleSubmit = () => {
    if (amount && amount > 0) {
      const list = toolsRouter.generatePrivatekeys(chain, amount);
      onSubmit(list);
    }
  };

  return (
    <Form layout="vertical">
      <Form.Item label="How many addereses?" required>
        <Space.Compact style={{ width: "100%" }}>
          <InputNumber
            min={1}
            max={100}
            value={amount}
            onChange={(value: any) => setAmount(value)}
            placeholder="Amount"
            style={{ width: "100%" }}
            formatter={(value) =>
              value ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""
            }
            // parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
          />
          <Button type="primary" onClick={handleSubmit} disabled={!amount}>
            Submit
          </Button>
        </Space.Compact>
      </Form.Item>
      <Divider />
      <Alert
        description="This will generate addresses and their private keys. You can export them afterward. Please make sure to save the generated private keys in a secure place if you need to reuse them. They will not be stored on the server and cannot be recovered."
        type="warning"
      />
    </Form>
  );
};

export default NewWalletGenerator;
