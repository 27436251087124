import React from "react";
import { MAINNET } from "../../../../../../consts/global";
import { Typography } from "antd";
import { RestAPI } from "../../../../../../packages/neo/api";
import { useOnChainData } from "../../../../../../common/hooks/use-onchain-data";
import { CHAINS } from "../../../../../../consts/chains";
import shortNumber from "@pogix3m/short-number";
import { formatSignificantNumbers } from "../../../../../../common/helpers";

interface ITokenDetailPageProps {
  chain: CHAINS;
  tokenId: string;
}
const TokenStats = ({ chain, tokenId }: ITokenDetailPageProps) => {
  const { data, isLoaded } = useOnChainData(() => {
    return new RestAPI(MAINNET).getNumbers(chain, tokenId);
  }, []);
  return (
    <div>
      <Typography.Text className="is-accent is-size-7">
        PRICE:${data && data.price ? formatSignificantNumbers(data.price) : 0},{" "}
      </Typography.Text>
      <Typography.Text className="is-accent is-size-7">
        MC:${data && data.marketCap ? shortNumber(data.marketCap) : 0},{" "}
      </Typography.Text>
      <Typography.Text className="is-accent is-size-7">
        LIQ:${data && data.liquidity ? shortNumber(data.liquidity) : 0}
      </Typography.Text>
    </div>
  );
};

export default TokenStats;
