import React from "react";
import TokenList from "../../../components/Commons/TokenList";
import { useApp } from "../../../../common/hooks/use-app";
import { IToken } from "../../../../consts/tokens";

interface ISelectTokenContractProps {
  hideTokens?: string[];
  onContractChange: (contract: IToken | undefined) => void;
  onClose: () => void;
}
const SelectTokenContract = ({
  hideTokens = [],
  onContractChange,
  onClose,
}: ISelectTokenContractProps) => {
  const { chain, network } = useApp();
  return (
    <>
      <TokenList
        chain={chain}
        network={network}
        activeTokenInput={"A"}
        tokenAHash={undefined}
        tokenBHash={undefined}
        hideTokens={hideTokens}
        onAssetClick={(token: IToken) => {
          onContractChange(token);
        }}
        onClose={onClose}
      />
    </>
  );
};

export default SelectTokenContract;
