import {
  MAINNET,
  TESTNET,
  SWAP,
  FARM,
  BRIDGE,
  SMITH,
} from "../../../consts/global";

export const POLYGON_MAINNET_CONTRACTS = {
  SWAP: "0x67DEE0d471D516221EF107c0d7aB6Ce538CbC4Ec",
  FARM: "0xD43802B83300b8308523fDCBF51A66be22AE719a",
  BRIDGE: "0x74C524D1C9F7A76356487196843e5b716e12a03F",
  SMITH: "0x10d597c79A8d5f7c993ef6569cCD1EDf7a1bB1a2",
  FNEO: "0xf7E2581087f2CFB9DcE4ce35cF6Be64E19140628",
  NEP: "0x8f6E6B2e36fbCB1e03BBc21009a8c03Dce8cf1B6",
  USDT: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
  WETH: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
  WMATIC: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
  MATIC: "0x0000000000000000000000000000000000001010",
  WBTC: "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
};

export const POLYGON_TESTNET_CONTRACTS = {
  SWAP: "0x7d421f7e8a635e0ccfc68e47de4df8571056ce21",
  FARM: "0x3a63d435d20bc370a1e3e8f1da03fa2b85576b99",
  BRIDGE: "0xF13f628b8CC51B409bc6F35bc76491239130D238",
  SMITH: "0x0D1B88A1a3625e4beD803AE8AEFB70cfF94350A9",
  FNEO: "0xf926223C4581135CF68B7312C41974FB6CAf50d8",
  NEP: "0x45e57764e6f31aD174A970FE3fbAE225E4E093E8",
  WMATIC: "0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889",
};

export const POLYGON_CONTRACTS = {
  [MAINNET]: {
    [SWAP]: POLYGON_MAINNET_CONTRACTS.SWAP,
    [FARM]: POLYGON_MAINNET_CONTRACTS.FARM,
    [BRIDGE]: POLYGON_MAINNET_CONTRACTS.BRIDGE,
    [SMITH]: POLYGON_MAINNET_CONTRACTS.SMITH,
  },
  [TESTNET]: {
    [SWAP]: POLYGON_TESTNET_CONTRACTS.SWAP,
    [FARM]: POLYGON_TESTNET_CONTRACTS.FARM,
    [BRIDGE]: POLYGON_TESTNET_CONTRACTS.BRIDGE,
    [SMITH]: POLYGON_TESTNET_CONTRACTS.SMITH,
  },
};

export const POLYGON_NEP = {
  [MAINNET]: POLYGON_MAINNET_CONTRACTS.NEP,
  [TESTNET]: POLYGON_TESTNET_CONTRACTS.NEP,
};

export const POLYGON_FNEO = {
  name: "Polygon ftwNEO",
  address: {
    [MAINNET]: POLYGON_MAINNET_CONTRACTS.FNEO,
    [TESTNET]: POLYGON_TESTNET_CONTRACTS.FNEO,
  },
};
