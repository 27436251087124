"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.signWithAccount = void 0;
const neon_core_1 = require("@cityofzion/neon-core");
function signWithAccount(acct) {
    return async (tx, details) => {
        const txData = tx.getMessageForSigning(details.network);
        const scriptHash = neon_core_1.wallet.getScriptHashFromVerificationScript(tx.witnesses[details.witnessIndex].verificationScript.toString());
        if (scriptHash !== acct.scriptHash) {
            throw new Error(`Requested signature from ${neon_core_1.wallet.getAddressFromScriptHash(scriptHash, acct.addressVersion)} but only have key of ${acct.address}.`);
        }
        return neon_core_1.wallet.sign(txData, acct.privateKey);
    };
}
exports.signWithAccount = signWithAccount;
