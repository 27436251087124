import { simulateContract, writeContract } from "@wagmi/core";
import { EVM_CONTRACTS } from "..";
import { CHAINS, CONFIGS } from "../../../consts/chains";
import { HELPERS } from "../../../consts/global";
import { INetworkType } from "../../neo/network";
import { wagmiConfig } from "../../../wagmi-config";
import FTWHelpers from "./abi/FTWHelpers.json";
import { IBatchTransaferAsset } from "../../../common/routers/tools";

export const batchTransfers = async (
  chain: CHAINS,
  network: INetworkType,
  transfers: IBatchTransaferAsset[]
) => {
  const tokens: string[] = [];
  const receivers: string[] = [];
  const amounts: string[] = [];
  transfers.forEach((item) => {
    tokens.push(item.tokenHash);
    amounts.push(item.amount);
    receivers.push(item.receiver);
  });

  const { request } = await simulateContract(wagmiConfig, {
    address: EVM_CONTRACTS[chain][network][HELPERS],
    abi: FTWHelpers,
    functionName: "multiTransfers",
    args: [tokens, receivers, amounts],
    chainId: CONFIGS[network][chain].chainId,
  });
  return await writeContract(wagmiConfig, request);
};
