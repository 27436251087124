import React from "react";
import Swaps from "./Swaps";
import { CHAINS } from "../../../../../../consts/chains";
import CandleChart from "./CandleChart";
import { MAINNET } from "../../../../../../consts/global";
import { Avatar, Button, Divider, Space, Typography } from "antd";
import { getTokenByHash } from "../../../../../../common/helpers";
import { useHistory } from "react-router-dom";
import { SWAP_PATH } from "../../../../../../consts/routes";
import { useApp } from "../../../../../../common/hooks/use-app";
import TokenStats from "./TokenStats";

interface ITokenDetailPageProps {
  chain: CHAINS;
  tokens: string[];
}
const TokenDetailPage = (props: ITokenDetailPageProps) => {
  const history = useHistory();
  const { chain, switchChain } = useApp();
  let token;
  if (props.tokens.length > 0) {
    token = getTokenByHash(props.chain, MAINNET, props.tokens[0]);
  }

  const handleTradeClick = () => {
    if (token) {
      if (chain !== props.chain) {
        switchChain(props.chain);
      }
      history.push(`${SWAP_PATH}?tokenB=${token.hash}`);
    }
  };

  if (!token) return null;

  return (
    <div>
      <Space
        size="small"
      >
        <Space>
          <Avatar size="small" src={token.icon} />
          <Typography.Title
            level={5}
            className="is-marginless is-hidden-mobile"
          >
            {token.symbol}
          </Typography.Title>
        </Space>
        <Space>
          <TokenStats chain={props.chain} tokenId={token.hash} />
        </Space>
        {/* <Button type="primary" onClick={handleTradeClick}>
          Trade on FTWSwap
        </Button> */}
      </Space>
      {/* <TokenStats chain={props.chain} tokenId={token.hash} /> */}
      <Divider />
      {token && (
        <>
          <CandleChart chain={props.chain} tokenHash={token.hash} />
        </>
      )}
      <Swaps {...props} />
    </div>
  );
};

export default TokenDetailPage;
