"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCandidates = void 0;
const neon_core_1 = require("@cityofzion/neon-core");
async function getCandidates(client) {
    const script = new neon_core_1.sc.ScriptBuilder()
        .emitContractCall(neon_core_1.sc.NeoContract.INSTANCE.getCandidates())
        .build();
    const res = await client.invokeScript(neon_core_1.u.HexString.fromHex(script));
    const arrayOfCandidates = res.stack;
    return arrayOfCandidates[0].value.map((i) => {
        return {
            publicKey: neon_core_1.u.HexString.fromBase64(i.value[0].value).toBigEndian(),
            votes: parseInt(i.value[1].value),
        };
    });
}
exports.getCandidates = getCandidates;
