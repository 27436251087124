import { IExcelData } from ".";

export const getEmptyBatchTransferAsset = (address = ""): IExcelData[] => {
  const item: IExcelData[] = [];
  item.push({
    type: String,
    value: address,
  });
  item.push({
    type: Number,
    value: 0,
  });
  item.push({
    type: String,
    value: "",
  });
  item.push({
    type: String,
    value: "",
  });
  // decimals
  item.push({
    type: Number,
    value: "",
  });

  return item;
};
