import {
  readContract,
  writeContract,
  simulateContract,
  getToken,
} from "@wagmi/core";
import FTWSmith from "./abi/FTWSmith.json";
import { CONTRACT_MAP } from "../../../consts/contracts";
import { INetworkType } from "../../neo/network";
import { SMITH } from "../../../consts/global";
import { ethers } from "ethers";
import { CHAINS, CONFIGS } from "../../../consts/chains";
import { wagmiConfig } from "../../../wagmi-config";
import { smithABI } from "./abi/smithAbi";

export const createTokenContract = async (
  chain: CHAINS,
  network: INetworkType,
  name: string,
  symbol: string,
  totalSupply: string,
  decimals: string,
  website: string,
  icon: string
): Promise<string> => {
  const { request } = await simulateContract(wagmiConfig, {
    address: CONTRACT_MAP[chain][network][SMITH] as any,
    abi: FTWSmith,
    functionName: "createToken",
    args: [name, symbol, totalSupply, decimals, icon, website],
  });
  return await writeContract(wagmiConfig, request);
};

export const setTokenData = async (
  chain: CHAINS,
  network: INetworkType,
  contractHash: string,
  icon: string,
  website: string
) => {
  const { request } = await simulateContract(wagmiConfig, {
    address: CONTRACT_MAP[chain][network][SMITH] as any,
    abi: FTWSmith,
    functionName: "setTokenData",
    args: [contractHash, icon, website],
  });
  return await writeContract(wagmiConfig, request);
};

export const getContractHashFromLogs = (logs: any) => {
  let iface = new ethers.Interface(FTWSmith);
  let contractHash;
  logs.forEach((log) => {
    try {
      const parsedLog = iface.parseLog(log);
      if (parsedLog?.name === "TokenCreated") {
        contractHash = parsedLog.args[0];
      }
    } catch (e) {}
  });
  return contractHash;
};

export const getTokenList = async (
  chain: CHAINS,
  network: INetworkType,
  page: number
) => {
  const contractAddress = CONTRACT_MAP[chain][network][SMITH];

  const res: any = await readContract(wagmiConfig, {
    address: contractAddress as `0x${string}`,
    abi: smithABI,
    functionName: "getTokens",
    args: [30, page],
    chainId: CONFIGS[network][chain].chainId,
  });
  return res;
};

export const getTokenMetadata = async (
  chain: CHAINS,
  network: INetworkType,
  contractHash: `0x${string}`
): Promise<{
  name: string;
  symbol: string;
  decimals: number;
  totalSupply: string;
  icon: string;
  website: string;
}> => {
  const chainId = CONFIGS[network][chain].chainId;

  const tokenData = await getToken(wagmiConfig, {
    address: contractHash,
    chainId,
  });

  // Fetch the token data (icon and website)
  const tokenSmithData = await readContract(wagmiConfig, {
    address: CONTRACT_MAP[chain][network][SMITH] as any,
    abi: FTWSmith as any,
    functionName: "getTokenData",
    args: [contractHash],
    chainId,
  });

  return {
    name: tokenData.name as string,
    symbol: tokenData.symbol as string,
    decimals: tokenData.decimals as number,
    totalSupply: ethers.formatUnits(
      tokenData.totalSupply.value,
      tokenData.decimals
    ),
    icon: tokenSmithData ? tokenSmithData[1] : "",
    website: tokenSmithData ? tokenSmithData[2] : "",
  };
};
