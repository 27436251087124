import React from "react";
import * as XLSX from "xlsx";
import { Alert, Space, Typography, Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";

const { Dragger } = Upload;

interface IImportAddressesProps {
  onSubmit: (list: { address: string; amount: number }[]) => void;
}

const ImportAddresses = ({ onSubmit }: IImportAddressesProps) => {
  const handleFile = (file: File) => {
    try {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target?.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        const list: { address: string; amount: number }[] = [];
        rows.forEach((row: any) => {
          const address = row[0];
          const amount = row[1];
          if (!address) return;
          list.push({
            address: address,
            amount: amount,
          });
        });
        onSubmit(list);
      };
      reader.readAsBinaryString(file);
    } catch (e) {
      console.error(e);
      message.error("Failed to read the file.");
    }
  };

  return (
    <Dragger
      accept=".xlsx,.xls"
      beforeUpload={(file) => {
        handleFile(file);
        return false; // Prevent automatic upload
      }}
      showUploadList={false} // Hide default file list
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload
      </p>
      <Alert
        description={
          <Typography.Paragraph>
            Upload an Excel file (.xlsx or .xls) <br />
            Ensure that the first column of the Excel file lists wallet
            addresses, and the second column specifies the corresponding
            amounts.
          </Typography.Paragraph>
        }
      />
    </Dragger>
  );
};

export default ImportAddresses;
