import { Button, Form, Input, Space } from "antd";
import React, { useState } from "react";
import NumberFormat from "react-number-format";

interface IBatchAmountProps {
  onSubmit: (amount: number) => void;
  onClose: () => void;
}
const BatchAmount = ({ onSubmit, onClose }: IBatchAmountProps) => {
  const [amount, setAmount] = useState<number | undefined>();

  return (
    <Form layout="vertical">
      <Form.Item label="Amount">
        <Space.Compact block>
          <NumberFormat
            allowLeadingZeros={false}
            thousandSeparator={true}
            allowNegative={false}
            inputMode="decimal"
            placeholder={"Amount"}
            onValueChange={(value) => {
              setAmount(value.floatValue);
            }}
            customInput={Input}
          />
          <Button type="primary" onClick={() => {
            if (amount && amount > 0) {
              onSubmit(amount);
            }
          }} disabled={!amount}>
            Submit
          </Button>
        </Space.Compact>
      </Form.Item>
    </Form>
  );
};

export default BatchAmount;
