import React, { useEffect, useState } from "react";
import PageLayout from "../../../../components/Commons/PageLayout";
import SelectTokenContract from "../../../Locker/Create/SelectTokenContract";
import { IToken } from "../../../../../consts/tokens";
import NumberFormat from "react-number-format";
import Level from "../../../../components/Level";
import Modal from "../../../../components/Modal";
import NewWalletGenerator from "./NewWalletGenerator";
import writeXlsxFile from "write-excel-file";
import { useApp } from "../../../../../common/hooks/use-app";
import { Button, Divider, Input, Space, Typography, message } from "antd";
import BatchAmount from "./BatchAmount";
import ImportAddresses from "./ImportAddresses";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import AirdropActionModal from "./AirdropActionModal";
import { NEO_CHAIN } from "../../../../../consts/global";
import { getEmptyBatchTransferAsset } from "./helpers";
import { PlusCircleOutlined } from "@ant-design/icons";
import { isAddress } from "@cityofzion/neon-core/lib/wallet";
import { ethers } from "ethers";
import { checkWalletNetwork } from "../../../../../common/helpers";
import { useWalletRouter } from "../../../../../common/hooks/use-wallet-router";
export interface IExcelData {
  type: any;
  value: any;
}

const Airdrop = () => {
  const { chain, network, setTxid } = useApp();
  const { client, isConnected, walletChainId } = useWalletRouter(chain);
  const [modalType, setModalType] = useState<
    "gernerate" | "import" | undefined
  >();
  const [tokenIndex, setTokenIndex] = useState<number | undefined>();
  const [batchAmountModal, setBatchAmountModal] = useState<boolean>(false);
  const [list, setList] = useState<IExcelData[][]>([]);
  const [isActionModalActive, setActionModalActive] = useState(false);

  const onImportFile = (addresses: { address: string; amount: number }[]) => {
    const newList: IExcelData[][] = [];
    if (!addresses || addresses.length === 0) {
      message.error("Failed");
      return;
    }
    addresses.forEach((row) => {
      const item: IExcelData[] = [];
      item.push({
        type: String,
        value: row.address,
      });
      item.push({
        type: Number,
        value: row.amount,
      });
      // symbol
      item.push({
        type: String,
        value: "",
      });
      // hash
      item.push({
        type: String,
        value: "",
      });
      // decimals
      item.push({
        type: Number,
        value: "",
      });
      newList.push(item);
    });
    setList([...list, ...newList]);
    setModalType(undefined);
  };

  const onNewWallets = (addresses: IExcelData[][]) => {
    const newList: IExcelData[][] = [];
    if (!addresses || addresses.length === 0) {
      message.error("Failed to import. Please check your excel file.");
      return;
    }
    addresses.forEach((row) => {
      const item = getEmptyBatchTransferAsset(row[0].value);
      item.push(row[1]);
      newList.push(item);
    });
    setList([...list, ...newList]);
    setModalType(undefined);
  };

  const onBatchAmount = (amount: number) => {
    const newList = [...list];
    setList(
      newList.map((item) => {
        item[1].value = amount;
        return item;
      })
    );
    setBatchAmountModal(false);
  };

  const onExport = async () => {
    await writeXlsxFile(list, {
      fileName: "wallets.xlsx",
    });
  };

  const onTokenChange = (token: IToken | undefined) => {
    if (!token) return false;

    const newList = [...list];
    if (tokenIndex === -1) {
      setList(
        newList.map((item) => {
          item[2].value = token.symbol;
          item[3].value = token.hash;
          item[4].value = token.decimals;
          return item;
        })
      );
    } else {
      setList(
        newList.map((item, i) => {
          if (i === tokenIndex) {
            item[2].value = token.symbol;
            item[3].value = token.hash;
            item[4].value = token.decimals;
          }
          return item;
        })
      );
    }

    setList(newList);
    setTokenIndex(undefined);
  };

  const onAmountChange = (value: number, index: number) => {
    const newList = [...list];
    setList(
      newList.map((item, i) => {
        if (i === index) {
          item[1].value = value;
        }
        return item;
      })
    );
  };

  const onAddressChange = (value: string, index: number) => {
    const newList = [...list];
    setList(
      newList.map((item, i) => {
        if (i === index) {
          item[0].value = value;
        }
        return item;
      })
    );
  };

  const onSend = () => {
    if (list.length === 0) {
      message.error("Please add addresses first.");
      return;
    }
    if (!isConnected) {
      message.error("Please connect your wallet first.");
      return;
    }

    for (let i = 0; i < list.length; i++) {
      const v = list[i];
      if (
        !v[0].value ||
        (chain === NEO_CHAIN && !isAddress(v[0].value)) ||
        (chain !== NEO_CHAIN && !ethers.isAddress(v[0].value)) ||
        !v[1].value ||
        v[1].value <= 0 ||
        !v[4].value
      ) {
        message.error(
          `Row ${i + 1} is incomplete. Please fill out all fields.`
        );
        return;
      }
    }

    if (!checkWalletNetwork(chain, network, walletChainId)) {
      return;
    }

    setActionModalActive(true);
  };

  const onReset = () => {
    if (window.confirm("Do you want to reset all addresses?")) {
      setList([]);
    }
  };

  const onDeleteRow = (index: number) => {
    const newList = list.filter((_, i) => i !== index);
    setList(newList);
  };

  const onAddRow = () => {
    const newList = [...list];
    newList.push(getEmptyBatchTransferAsset());
    setList(newList);
  };

  useEffect(() => {
    if (list.length !== 0) {
      setList([]);
    }
  }, [chain]);

  return (
    <PageLayout>
      <div className="columns is-centered">
        <div className="column is-half">
          <div className="box is-shadowless">
            <h1 className="title is-5">Batch Transfer</h1>
            <p className="subtitle is-7">
              This tool helps multiple token transfers in one transaction.
              Please use with caution.
              <br />
              <span className="is-italic is-underlined">
                It doesn't support native currency tokens that don't use
                contracts, such as ETH and GAS on EVM.
              </span>
            </p>
            <Divider />

            {list.length === 0 ? (
              <Space>
                <Button onClick={() => setModalType("import")}>
                  Import wallets
                </Button>
                <Button onClick={() => setModalType("gernerate")}>
                  Generate new wallets
                </Button>
              </Space>
            ) : (
              <>
                <Space>
                  <Button onClick={() => setBatchAmountModal(true)}>
                    Set All Amounts
                  </Button>
                  <Button onClick={() => setTokenIndex(-1)}>
                    Set All Tokens
                  </Button>
                </Space>
              </>
            )}

            <>
              <Divider />
              <div className="table-container">
                <table className="table is-fullwidth">
                  <thead>
                    <tr>
                      <th>Address</th>
                      <th>Amount</th>
                      <th colSpan={2}>Token</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((row, i) => (
                      <tr key={"row" + i}>
                        {row.map((r, ri) => {
                          if (ri === 0)
                            return (
                              <td key={"walletindex" + ri}>
                                <Input
                                  value={r.value}
                                  onChange={(e) =>
                                    onAddressChange(e.target.value, i)
                                  }
                                />
                              </td>
                            );
                          if (ri === 1)
                            return (
                              <td key={"walletindex" + ri}>
                                <NumberFormat
                                  value={r.value}
                                  allowLeadingZeros={false}
                                  thousandSeparator={true}
                                  allowNegative={false}
                                  inputMode="decimal"
                                  placeholder={"Amount"}
                                  onValueChange={(value) => {
                                    onAmountChange(
                                      value.floatValue as number,
                                      i
                                    );
                                  }}
                                  customInput={Input}
                                />
                              </td>
                            );
                          if (ri === 2)
                            return (
                              <td key={"walletindex" + ri}>
                                {r.value ? (
                                  <Space>
                                    <Typography.Text strong>
                                      {r.value}
                                    </Typography.Text>
                                    <Button
                                      onClick={() => setTokenIndex(i)}
                                      size="small"
                                      icon={<EditOutlined />}
                                    ></Button>
                                  </Space>
                                ) : (
                                  <Button
                                    icon={<PlusCircleOutlined />}
                                    onClick={() => setTokenIndex(i)}
                                    size="small"
                                    type="dashed"
                                  >
                                    Token
                                  </Button>
                                )}
                              </td>
                            );
                        })}
                        <td>
                          <Button
                            type="dashed"
                            onClick={() => onDeleteRow(i)}
                            danger
                            size="small"
                            icon={<DeleteOutlined />}
                          ></Button>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td>
                        <Button
                          size="small"
                          icon={<PlusCircleOutlined />}
                          type="dashed"
                          onClick={onAddRow}
                        >
                          Add
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Divider />
              <Level
                isMobile
                left={
                  <Button onClick={onSend} type="primary">
                    Send
                  </Button>
                }
                right={
                  <div className="buttons">
                    <Button onClick={onExport} disabled={list.length === 0}>
                      Export
                    </Button>
                  </div>
                }
              />
            </>

            {modalType ? (
              <Modal onClose={() => setModalType(undefined)}>
                <>
                  {modalType === "gernerate" && (
                    <NewWalletGenerator onSubmit={onNewWallets} />
                  )}

                  {modalType === "import" && (
                    <ImportAddresses onSubmit={onImportFile} />
                  )}
                </>
              </Modal>
            ) : null}

            {tokenIndex !== undefined ? (
              <Modal onClose={() => setTokenIndex(undefined)}>
                <>
                  <SelectTokenContract
                    hideTokens={[""]}
                    onContractChange={onTokenChange}
                    onClose={() => setTokenIndex(undefined)}
                  />
                </>
              </Modal>
            ) : null}

            {batchAmountModal ? (
              <Modal onClose={() => setBatchAmountModal(false)}>
                <>
                  <BatchAmount
                    onSubmit={onBatchAmount}
                    onClose={() => setBatchAmountModal(false)}
                  />
                </>
              </Modal>
            ) : null}

            {isActionModalActive && (
              <AirdropActionModal
                chain={chain}
                network={network}
                list={list}
                connectedWallet={client}
                onSuccess={() => {}}
                onCancel={() => setActionModalActive(false)}
              />
            )}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default Airdrop;
