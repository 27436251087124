"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFeeInformation = void 0;
const neon_core_1 = require("@cityofzion/neon-core");
/**
 * Helper method for retrieving fee-related information from PolicyContract.
 */
async function getFeeInformation(client) {
    const policyScript = new neon_core_1.sc.ScriptBuilder()
        .emitContractCall(neon_core_1.sc.PolicyContract.INSTANCE.getFeePerByte())
        .emitContractCall(neon_core_1.sc.PolicyContract.INSTANCE.getExecFeeFactor())
        .build();
    const res = await client.invokeScript(neon_core_1.u.HexString.fromHex(policyScript));
    const [feePerByte, executionFeeFactor] = res.stack.map((s) => neon_core_1.u.BigInteger.fromNumber(s.value));
    return { feePerByte, executionFeeFactor };
}
exports.getFeeInformation = getFeeInformation;
