"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTokenInfos = void 0;
const neon_core_1 = require("@cityofzion/neon-core");
const CHUNK_SIZE = 3;
async function getTokenInfos(contracts, client) {
    const script = contracts
        .map((scriptHash) => scriptHash instanceof neon_core_1.sc.Nep17Contract
        ? scriptHash
        : new neon_core_1.sc.Nep17Contract(scriptHash))
        .map((contract) => [
        contract.symbol(),
        contract.decimals(),
        contract.totalSupply(),
    ])
        .reduce((sb, contractCalls) => {
        contractCalls.forEach((cc) => sb.emitContractCall(cc));
        return sb;
    }, new neon_core_1.sc.ScriptBuilder())
        .build();
    const response = await client.invokeScript(neon_core_1.u.HexString.fromHex(script));
    if (response.state === "FAULT") {
        throw new Error(response.exception
            ? `Invoke exception: ${response.exception}}`
            : "No exception message returned.");
    }
    const expectedStackLength = contracts.length * CHUNK_SIZE;
    if (response.stack.length !== expectedStackLength) {
        throw new Error(`Received unexpected results. Expected ${expectedStackLength} but got ${response.stack.length} instead.`);
    }
    const results = [];
    for (let i = 0; i < response.stack.length; i += CHUNK_SIZE) {
        results.push(response.stack.slice(i, i + CHUNK_SIZE));
    }
    return results.map((result) => {
        const decimals = parseInt(result[1].value);
        return {
            symbol: neon_core_1.u.HexString.fromBase64(result[0].value).toAscii(),
            decimals,
            totalSupply: neon_core_1.u.BigInteger.fromNumber(result[2].value).toDecimal(decimals),
        };
    });
}
exports.getTokenInfos = getTokenInfos;
