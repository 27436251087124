import React, { useState } from "react";
import { useApp } from "../../../../common/hooks/use-app";
import { CHAINS, CONFIGS, LIST } from "../../../../consts/chains";
import { Avatar, Button, Modal, Space } from "antd";

const DisplayCurrentChain = () => {
  const [isActive, setActive] = useState(false);
  const { chain, network, switchChain, closeSidebar, closealletSidebar } =
    useApp();
  const onActive = () => {
    closeSidebar();
    closealletSidebar();
    setActive(!isActive);
  };
  const handleSwitch = async (v: CHAINS) => {
    switchChain(v);
    onActive();
  };
  return (
    <>
      <Button
        style={{ width: "100px" }}
        onClick={onActive}
        // aria-controls="dropdown-wallet"
        icon={
          <Space>
            <Avatar
              size="small"
              alt="Forthewin Logo"
              src={"/logo/FTW_512_512.svg"}
            />
            <Avatar
              size="small"
              src={CONFIGS[network][chain].icon}
              alt={`${CONFIGS[network][chain].label} icon`}
            />
          </Space>
        }
      ></Button>
      <Modal open={isActive} onCancel={() => setActive(false)} footer={[]}>
        <div>
          <h5 className="title is-6 has-text-centered">Chain switch</h5>
          <Space direction="vertical" style={{ width: "100%" }}>
            {LIST.map((v) => {
              if (chain === v) {
                return null;
              }
              const chainObj = CONFIGS[network][v];
              return (
                <Button
                  style={{ width: "100%" }}
                  key={`chain${v}`}
                  onClick={() => handleSwitch(v as CHAINS)}
                >
                  <Space>
                    <Avatar
                      style={{ display: "flex" }}
                      size={20}
                      src={chainObj.icon}
                    />
                    {CONFIGS[network][v].label}
                  </Space>
                </Button>
              );
            })}
          </Space>
        </div>
      </Modal>
    </>
  );
};

export default DisplayCurrentChain;
